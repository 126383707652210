html,
body{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.container{
  margin: 80px auto 0 auto;
  max-width: 1200px;
}
.nav-container{
  margin:auto;
}